import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { notify } from '../../../../common/utils/notify';
import { Button } from '../../../../components/Buttons/Button';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter } from '../../../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../../../components/ContentBlock/ContentBlockHeading';
import withSuspense from '../../../../common/hocs/withSuspense';
import { createDataId } from '../../../../common/utils/dataId';
import FormikField from '../../../../common/utils/FormikField';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { TextInputField, TextInputType } from '../../../../components/TextInput/TextInput';
import { WithTranslateFormErrors } from '../../../../components/WithTranslateFormErrors/WithTranslateFormErrors';
import api from '../../../../services/ApiServices';
import PasswordRequirements from '../../../../components/UserProfile/components/ChangePassword/PasswordRequirements';
import { passwordMatch } from '../../../../components/UserProfile/components/ChangePassword/utils';
import { isResetPasswordTokenExpired } from '../../loginHelper';
import { ResetPasswordSuccessModal } from './ResetPasswordSuccess';
import ResetPasswordExpiredModal from './ResetPasswordExpiredTokenModal';

import './ResetPassword.scss';

export interface ResetPasswordProps extends WithTranslation {
    email: string;
    token: string;
    selectedCountry: string;
}

export interface ResetPasswordFields {
    password: string;
    passwordRepeat: string;
}

const ResetPassword = (props: ResetPasswordProps) => {
    const { selectedCountry, token, t } = props;
    const [isLoading, setIsloading] = useState(false);
    const [showSuccessModal, setShowSuccesModal] = useState(false);
    const [showExpiredTokenModal, setShowExpiredTokenModal] = useState(false);

    const getInitialFields = (): ResetPasswordFields => {
        return {
            password: '',
            passwordRepeat: '',
        };
    };

    const validationSchema: Yup.ObjectSchema<ResetPasswordFields> = Yup.object<ResetPasswordFields>().shape({
        passwordRepeat: Yup.string()
            .ensure()
            .oneOf([Yup.ref('password')], t('component.changePassword.passwordsDoNotMatch'))
            .nullable(false),
    });

    const onSubmit = async (fields: ResetPasswordFields) => {
        setIsloading(true);
        try {
            const result = await api.boUsers.recoverUserPassword({ Password: fields.password, VerificationToken: token, Country: selectedCountry });
            if (result.data.Success) {
                setShowSuccesModal(true);
            } else {
                if (isResetPasswordTokenExpired(result.data.Message)) {
                    setShowExpiredTokenModal(true);
                } else {
                    notify.error(t(result.data.Message), t('EmailValidation.error.TokenNotFound'));
                }
            }
        } catch (e) {
            notify.error(t('views.global.generalError'));
        }
        setIsloading(false);
    };

    const renderForm = () => {
        return (
            <Formik initialValues={getInitialFields()} onSubmit={onSubmit} enableReinitialize={true} validationSchema={validationSchema} validateOnBlur={true} validateOnChange={true}>
                {(formik) => (
                    <WithTranslateFormErrors errors={formik.errors} touched={formik.touched} setFieldTouched={formik.setFieldTouched}>
                        <Form className="reset-password__form">
                            <ContentBlock className="reset-password__block">
                                <ContentBlockHeader className="reset-password__block__header">
                                    <ContentBlockHeading type={ContentBlockHeadingType.BOLD} dataId={createDataId('ResetPassword', 'heading')}>
                                        {t('views.login.forgotPassword.setPassword')}
                                    </ContentBlockHeading>
                                </ContentBlockHeader>
                                <ContentBlockBody className="reset-password__block__body" dataId={createDataId('ResetPassword', 'block')}>
                                    <FormikField
                                        label={t('view.UserList.NewUserPopover.InputLabel.Password')}
                                        component={TextInputField}
                                        name="password"
                                        password={true}
                                        type={TextInputType.BORDERED}
                                        dataId={createDataId('ResetPassword', 'Password')}
                                        autosize={true}
                                    />
                                    <FormikField
                                        label={t('component.changePassword.confirmPassword')}
                                        component={TextInputField}
                                        name="passwordRepeat"
                                        password={true}
                                        type={TextInputType.BORDERED}
                                        dataId={createDataId('ResetPassword', 'PasswordRepeat')}
                                        autosize={true}
                                    />
                                    <PasswordRequirements
                                        isPristine={!formik.touched.passwordRepeat}
                                        isMatch={passwordMatch(formik.values.password, formik.values.passwordRepeat)}
                                        value={formik.values.passwordRepeat}
                                        t={t}
                                    />
                                </ContentBlockBody>
                                <ContentBlockFooter className="reset-password__block__footer" noSeparator={false}>
                                    <Button
                                        type="submit"
                                        loading={isLoading}
                                        disabled={isLoading || !formik.isValid}
                                        dataId={createDataId('ResetPassword', 'button')}
                                        className="reset-password__button"
                                    >
                                        {t('views.login.continueButton')}
                                    </Button>
                                </ContentBlockFooter>
                            </ContentBlock>
                        </Form>
                    </WithTranslateFormErrors>
                )}
            </Formik>
        );
    };

    const renderText = (text: string) => {
        return (
            <ContentBlock className="reset-password__expiry">
                <ContentBlockBody className="reset-password__expiry__body" dataId={createDataId('ResetPassword', 'link', 'expiry')}>
                    {text}
                </ContentBlockBody>
            </ContentBlock>
        );
    };

    return isLoading ? (
        renderText(`${t('interceptorsFactory.PleaseWait')}...`)
    ) : showSuccessModal ? (
        <ResetPasswordSuccessModal email={props.email} t={t} />
    ) : showExpiredTokenModal ? (
        <ResetPasswordExpiredModal email={props.email} t={t} />
    ) : (
        renderForm()
    );
};

export default withSuspense(withTranslation()(ResetPassword));
