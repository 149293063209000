import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './Onboarding.scss';
import withSuspense from '../../../../common/hocs/withSuspense';
import api from '../../../../services/ApiServices';
import { OnboardDTO } from 'src/services/controllers/LoginService';
import { logoutKeycloak, redirectToKeycloakAuth, codeVerifierKey, redirectUrlKey } from './OnboardingHelper';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import { Button } from '../../../../components/Buttons/Button';
import { createDataId } from '../../../../common/utils/dataId';
import FormikField from '../../../../common/utils/FormikField';
import { TextInputField, TextInputType } from '../../../../components/TextInput/TextInput';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { redirectToApp, initSession } from '../../loginHelper';

export interface OnboardingProps extends WithTranslation {
    selectedLanguage: string;
}

export interface OnboardingFields {
    FirstName: string;
    LastName: string;
    Country: string;
    RegistrationNumber: string;
    CompanyName: string;
    VATCode: string;
    Email: string;
}

const Onboarding = (props: OnboardingProps) => {
    const { selectedLanguage, t } = props;
    const uri = new URL(window.location.href);
    const invitationToken = uri.searchParams.get('invitation_token');
    const keycloakCode = uri.searchParams.get('code');

    const [onboardingFields, setOnboardingFields] = useState<OnboardingFields>(null);
    const [loadedData, setLoadedData] = useState<OnboardDTO>(null);
    const [showSetup, setShowSetup] = useState<boolean>(false);
    const [accepted, setAccepted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!onboardingFields && invitationToken) {
            if (keycloakCode) {
                // we've got the code, let's retrieve user data
                api.login
                    .getOnboardingData({
                        KeycloakCode: keycloakCode,
                        OnboardToken: invitationToken,
                        RedirectUri: localStorage.getItem(redirectUrlKey),
                        Verifier: localStorage.getItem(codeVerifierKey),
                    })
                    .then((result) => {
                        if (result.data) {
                            setOnboardingFields({
                                FirstName: result.data.User?.FirstName,
                                LastName: result.data.User?.LastName,
                                Email: result.data.User?.Email,
                                CompanyName: result.data.Company?.CompanyName,
                                VATCode: result.data.Company?.VATCode,
                                RegistrationNumber: result.data.Company?.RegistrationNumber,
                                Country: result.data.Company?.Country,
                            });
                            setLoadedData(result.data);
                        } else {
                            logoutKeycloak();
                        }
                    }, logoutKeycloak);
            } else {
                redirectToKeycloakAuth(uri.toString(), selectedLanguage);
            }
        }
    }, [onboardingFields]);

    const validationSchema = Yup.object<OnboardDTO>().shape({
        FirstName: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: t('views.components.onboarding.setup.firstName') })),
        LastName: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: t('views.components.onboarding.setup.lastName') })),
        VATCode: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: t('views.components.onboarding.setup.regCode') })),
    });

    const termsSegments = (
        <span>
            {t('views.components.onboarding.setup.terms').split('[')[0]}
            <a href="https://www.unifiedpost.com/" target="_blank" rel="noopener noreferrer">
                {
                    t('views.components.onboarding.setup.terms')
                        .split('[')[1]
                        .split(']')[0]
                }
            </a>
            {t('views.components.onboarding.setup.terms').split(']')[1]}
        </span>
    );

    const handleSubmit = async (request: OnboardingFields) => {
        setLoading(true);
        await api.login
            .onboardRequest({
                FirstName: request.FirstName,
                LastName: request.LastName,
                VATCode: request.VATCode,
                OnboardToken: invitationToken,
                CommunityApiToken: loadedData.CommunityApiToken,
            })
            .then(async (result) => {
                if (result?.data?.Session?.UserLastCompanyGuid) {
                    await api.login.onboardCommunityCreditors(loadedData.CommunityApiToken).then(async (community) => {
                        if (community.data) {
                            const suppliers = community.data.map((creditor) => ({
                                Name: creditor.name,
                                RegistrationCode: creditor.legal_entity_trn,
                                VatCode: creditor.vat_code,
                            }));
                            await api.login.onboardAddSuppliers(suppliers, result.data.Session.UserLastCompanyGuid);
                        }
                        initSession(result.data.Session, null);
                        redirectToApp(result.data.Session);
                    }, logoutKeycloak);
                } else {
                    logoutKeycloak();
                }
            }, logoutKeycloak);
    };

    return (
        <>
            {!showSetup && !loading && onboardingFields && (
                <section className="onboarding__container">
                    <ContentBlock className="onboarding__block">
                        <div className="heading">
                            {t('views.components.onboarding.greeting')} {onboardingFields.FirstName} {onboardingFields.LastName},
                        </div>
                        <div className="heading">{t('views.components.onboarding.welcome')}</div>
                        <div className="heading-description">{t('views.components.onboarding.heading')}</div>
                        <ContentBlockHeader className="onboarding__block-header">
                            <div className="header">{t('views.components.onboarding.intro')}</div>
                            <div className="body">{t('views.components.onboarding.intro.description')}</div>
                            <div className="items">
                                <ul>
                                    {t('views.components.onboarding.intro.items')
                                        .split(',')
                                        .map((p) => (
                                            <li key={p}>· {p}</li>
                                        ))}
                                </ul>
                            </div>
                            <div className="header">{t('views.components.onboarding.billing')}</div>

                            <div className="items">
                                <ul>
                                    {t('views.components.onboarding.billing.items')
                                        .split(',')
                                        .map((p) => (
                                            <li key={p}>· {p}</li>
                                        ))}
                                </ul>
                            </div>
                            <Button dataId="onboarding.start" type="submit" className="onboarding__button" onClick={() => setShowSetup(true)}>
                                {t('views.components.onboarding.start')}
                            </Button>
                        </ContentBlockHeader>
                    </ContentBlock>
                </section>
            )}
            {showSetup && !loading && (
                <section className="onboarding__container">
                    <ContentBlock className="onboarding__block">
                        <div className="heading">
                            {t('views.components.onboarding.greeting')} {onboardingFields.FirstName} {onboardingFields.LastName},
                        </div>
                        <div className="heading">{t('views.components.onboarding.setup.heading')}</div>
                        <div className="heading-description">{t('views.components.onboarding.setup.heading.description')}</div>
                        <ContentBlockHeader className="onboarding__block-header">
                            <Formik
                                onSubmit={(values: OnboardingFields) => {
                                    handleSubmit(values);
                                }}
                                initialValues={onboardingFields}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                validateOnBlur={true}
                                validateOnChange={true}
                            >
                                {(formik: FormikProps<OnboardingFields>) => (
                                    <Form>
                                        <div className="onboarding__form">
                                            <div className="header">{t('views.components.onboarding.setup.intro')}</div>
                                            <div className="body">{t('views.components.onboarding.setup.intro.description')}</div>
                                            <div className="section">{t('views.components.onboarding.setup.companyInformation')}</div>
                                            <div className="onboarding__row">
                                                <div className="field">
                                                    <FormikField
                                                        dataId={createDataId('onboarding', 'companyName')}
                                                        onlyChangeOnBlur={true}
                                                        component={TextInputField}
                                                        name="CompanyName"
                                                        type={TextInputType.BORDERED}
                                                        label={t('views.components.onboarding.setup.companyName')}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="field">
                                                    <FormikField
                                                        dataId={createDataId('onboarding', 'regCode')}
                                                        onlyChangeOnBlur={true}
                                                        component={TextInputField}
                                                        name="RegistrationNumber"
                                                        type={TextInputType.BORDERED}
                                                        label={t('views.components.onboarding.setup.regCode')}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="onboarding__row">
                                                <div className="field">
                                                    <FormikField
                                                        dataId={createDataId('onboarding', 'country')}
                                                        onlyChangeOnBlur={true}
                                                        component={TextInputField}
                                                        name="Country"
                                                        type={TextInputType.BORDERED}
                                                        label={t('views.components.onboarding.setup.country')}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="section">{t('views.components.onboarding.setup.userInformation')}</div>
                                            <div className="onboarding__row">
                                                <div className="field">
                                                    <FormikField
                                                        dataId={createDataId('onboarding', 'firstName')}
                                                        onlyChangeOnBlur={true}
                                                        component={TextInputField}
                                                        name="FirstName"
                                                        type={TextInputType.BORDERED}
                                                        label={t('views.components.onboarding.setup.firstName')}
                                                        maxLength={64}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="field">
                                                    <FormikField
                                                        dataId={createDataId('onboarding', 'lastName')}
                                                        onlyChangeOnBlur={true}
                                                        component={TextInputField}
                                                        name="LastName"
                                                        type={TextInputType.BORDERED}
                                                        label={t('views.components.onboarding.setup.lastName')}
                                                        maxLength={64}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="field-full">
                                                <FormikField
                                                    dataId={createDataId('onboarding', 'email')}
                                                    onlyChangeOnBlur={true}
                                                    component={TextInputField}
                                                    name="Email"
                                                    type={TextInputType.BORDERED}
                                                    label={t('views.components.onboarding.setup.email')}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="field-full">
                                                <Checkbox
                                                    className="onboarding__checkbox"
                                                    data-id={createDataId('onboarding', 'terms')}
                                                    name="user.details.monetaryLimits.unlimited.checkbox"
                                                    onChange={() => {
                                                        setAccepted(!accepted);
                                                    }}
                                                    label={termsSegments}
                                                />
                                            </div>
                                            <div className="field-full-desc">{t('views.components.onboarding.setup.terms.description')}</div>
                                            <h1>{formik.isValid}</h1>
                                            <h1>{accepted}</h1>

                                            <Button dataId="onboarding.submit" type="submit" className="onboarding__button" loading={loading} disabled={!accepted}>
                                                {t('views.components.onboarding.submit')}
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </ContentBlockHeader>
                    </ContentBlock>
                </section>
            )}
            {loading && (
                <div className="onboarding__importing">
                    <h2>{t('views.components.onboarding.thanks')}</h2>
                    <p>{t('views.components.onboarding.importing')}</p>
                    <div className="onboarding__loading"></div>
                </div>
            )}
        </>
    );
};

export default withSuspense(withTranslation()(Onboarding));
