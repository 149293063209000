import { TFunction } from 'i18next';
import * as React from 'react';
import Icon, { ICONS } from '../../../components/Icon/Icon';
import { SUPPORTED_COUNTRIES } from '../loginHelper';

export interface CountryButtonProps {
    children?: React.ReactNode;
    onClick?: () => void;
    countryCode: string;
    t: TFunction;
}

function getCountry(countryCode: string) {
    return SUPPORTED_COUNTRIES.find((c) => c.value === countryCode);
}

const CountryButton: React.FunctionComponent<CountryButtonProps> = ({ onClick, countryCode, t }: CountryButtonProps) => {
    const country = getCountry(countryCode);
    if (!country) {
        return null;
    }
    return (
        <div className="login__country-button">
            <span>{t('views.login.region')}</span>
            <button onClick={onClick} data-id="country.button">
                <Icon className="login__flag-icon" name={ICONS.OTHER_BROWSER} />
                {t(country.text)}
                <Icon className="login__arrow-down-icon" name={ICONS.ARROW_DOWN_SMALL} />
            </button>
        </div>
    );
};

export default CountryButton;
