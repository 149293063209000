import * as React from 'react';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';
import { ICONS } from '../../../../components/Icon/Icon';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { createDataId } from '../../../../common/utils/dataId';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../../../components/ContentBlock/ContentBlockHeading';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter } from '../../../../components/ContentBlock/ContentBlockFooter';
import { checkEmailStatus, redirectToLogin, sendPasswordResetLink } from '../../loginHelper';
import EmailSent from '../EmailSent';

export interface Props {
    email: string;
    t: TFunction;
}

export type ResetPasswordExpiredModalProps = Props;

const dataId = 'resetPasswordExpiredModal';

export const ResetPasswordExpiredModal = (props: ResetPasswordExpiredModalProps) => {
    const { t } = props;
    const [showEmailSentModal, setShowEmailSentModal] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const redirectToForgotPassword = async () => {
        window.location.href = '/login/?forgot_password';
    };

    const handleResendLink = async () => {
        setIsLoading(true);
        const checkStatus = await checkEmailStatus(props.email);
        if (checkStatus) {
            const sendResult = await sendPasswordResetLink(props.email);

            if (sendResult) {
                setShowEmailSentModal(true);
            }
        }
        setIsLoading(false);
    };

    return (
        <section className="reset-password__container">
            {showEmailSentModal ? (
                <ContentBlock className="reset-password__block">
                    <EmailSent email={props.email} />
                </ContentBlock>
            ) : (
                <ContentBlock className="reset-password__block">
                    <ContentBlockHeader className="reset-password__block__header">
                        <Button
                            className="reset-password__block--back-btn"
                            onClick={redirectToLogin}
                            dataId={createDataId(dataId, 'backBtn')}
                            buttonType={ButtonType.ICON_TEXT}
                            icon={ICONS.ARROW_LEFT_24}
                        >
                            {t('views.login.forgotPassword.backToLogin')}
                        </Button>
                        <div className="reset-password__block-header-container">
                            <ContentBlockHeading type={ContentBlockHeadingType.BOLD} dataId={createDataId(dataId, 'headingBlockTitle')}>
                                {t('views.login.forgotPassword.setPassword.tokenExpired.title')}
                            </ContentBlockHeading>
                        </div>
                    </ContentBlockHeader>
                    <ContentBlockBody className="reset-password__block__body" dataId={createDataId(dataId, 'blockBody')}>
                        <h4>{t('views.login.forgotPassword.setPassword.tokenExpired.subTitle')}</h4>
                        <p>{t('views.login.forgotPassword.setPassword.tokenExpired.body')}</p>
                    </ContentBlockBody>
                    <ContentBlockFooter className="reset-password__block__footer" noSeparator={false}>
                        <Button onClick={handleResendLink} loading={isLoading} disabled={isLoading} dataId={createDataId(dataId, 'btn.resend')} className="reset-password__button">
                            {t('views.login.forgotPassword.button.resendLink')}
                        </Button>
                        <p className="reset-password__block__footer--info-title">
                            {
                                <Trans i18nKey={'views.login.forgotPassword.emailSentTo'} values={{ email: props.email }}>
                                    <b>{props.email}</b>
                                </Trans>
                            }
                        </p>
                        <Button buttonType={ButtonType.TEXT} onClick={redirectToForgotPassword} dataId={createDataId(dataId, 'btn.changeEmail')} className="reset-password__block__footer--btn">
                            {t('views.login.forgotPassword.changeEmail')}
                        </Button>
                    </ContentBlockFooter>
                </ContentBlock>
            )}
        </section>
    );
};

export default ResetPasswordExpiredModal;
