import * as React from 'react';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';
import { Button } from '../../../../components/Buttons/Button';
import { createDataId } from '../../../../common/utils/dataId';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../../../components/ContentBlock/ContentBlockHeading';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter } from '../../../../components/ContentBlock/ContentBlockFooter';
import { redirectToLogin } from '../../loginHelper';

export interface Props {
    email: string;
    t: TFunction;
}

export type ResetPasswordSuccessModalProps = Props;

const dataId = 'resetPasswordSuccessModal';

export const ResetPasswordSuccessModal = (props: ResetPasswordSuccessModalProps) => {
    const { t } = props;

    return (
        <section className="reset-password__container">
            <ContentBlock className="reset-password__block">
                <ContentBlockHeader className="reset-password__block__header">
                    <div className="reset-password__block__header-container">
                        <ContentBlockHeading type={ContentBlockHeadingType.BOLD} dataId={createDataId(dataId, 'headingBlockTitle')}>
                            {t('views.login.forgotPassword.setPassword')}
                        </ContentBlockHeading>
                    </div>
                </ContentBlockHeader>
                <ContentBlockBody className="reset-password__block__body" dataId={createDataId(dataId, 'blockBody')}>
                    <h4>{t('view.PurchaseOrders.Supplier.Add.SuccessTitle')}</h4>
                    <p>
                        {
                            <Trans i18nKey={'views.login.forgotPassword.setPassword.success.body'} values={{ email: props.email }}>
                                <b>{props.email}</b>
                            </Trans>
                        }
                    </p>
                </ContentBlockBody>
                <ContentBlockFooter className="reset-password__block__footer" noSeparator={false}>
                    <Button onClick={redirectToLogin} dataId={createDataId(dataId, 'button.backToLoginPage')} className="reset-password__button">
                        {t('views.login.forgotPassword.backToLoginPage')}
                    </Button>
                </ContentBlockFooter>
            </ContentBlock>
        </section>
    );
};

export default ResetPasswordSuccessModal;
