import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import withSuspense from '../../../../common/hocs/withSuspense';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import { ContentBlockFooter } from '../../../../components/ContentBlock/ContentBlockFooter';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../../../components/ContentBlock/ContentBlockHeading';
import { createDataId } from '../../../../common/utils/dataId';
import api from '../../../../services/ApiServices';
import { redirectToLogin } from '../../loginHelper';
import { notify } from '../../../../common/utils/notify';
import { Button } from '../../../../components/Buttons/Button';

import './EmailVerification.scss';

export interface EmailVerificationProps extends WithTranslation {
    token: string;
}

const dataId = 'loginEmailVerification';

const EmailVerification = (props: EmailVerificationProps) => {
    const { token, t } = props;
    const [isLoading, setIsloading] = useState(false);
    const [isTokenValid, setIsTokenValid] = useState(false);

    useEffect(() => {
        setIsloading(true);
        api.boUsers
            .verifyUserEmailByToken(token)
            .then((response) => {
                if (response.data.Success) {
                    setIsTokenValid(true);
                    notify.success(t('views.login.emailVerification.verify.success'));
                } else {
                    notify.error(t(response.data.Message));
                    setIsTokenValid(false);
                }
                setIsloading(false);
            })
            .catch((e) => {
                console.error(e);
                notify.error(t('component.emailVerification.verify.error'));
                setIsloading(false);
            });
    }, []);

    return (
        <section className="email-verification__container">
            <ContentBlock className="email-verification__block">
                <ContentBlockHeader className="login__block-header">
                    <div className="login__block-header-container">
                        <ContentBlockHeading type={ContentBlockHeadingType.BOLD} dataId={createDataId(dataId, 'headingBlockTitle')}>
                            {t('views.login.emailVerification.title')}
                        </ContentBlockHeading>
                    </div>
                </ContentBlockHeader>
                <ContentBlockBody className="login__block-body forgot-password" dataId={createDataId(dataId, 'blockBody')}>
                    {isLoading ? `${t('interceptorsFactory.PleaseWait')}...` : isTokenValid ? t('views.global.emailVerification.completedBody') : t('EmailActivation_Error_Verification_Code_Expired')}
                </ContentBlockBody>
                <ContentBlockFooter className="email-verification__block__footer" noSeparator={false}>
                    <Button onClick={redirectToLogin} dataId={createDataId(dataId, 'button.backToLoginPage')} className="email-verification__block__button">
                        {t('views.login.forgotPassword.backToLoginPage')}
                    </Button>
                </ContentBlockFooter>
            </ContentBlock>
        </section>
    );
};

export default withSuspense(withTranslation()(EmailVerification));
