import api from '../../../../services/ApiServices';
import { isEmpty } from 'lodash-es';
import { encode as base64encode } from 'base64-arraybuffer';

export const codeVerifierKey = 'KEYCLOCK_CODE_MODIFIER';
export const redirectUrlKey = 'KEYCLOCK_REDIRECT_URL';

export function redirectToKeycloakAuth(uri: string, locale: string) {
    const redirectUri = uri.toString();
    generateHashedVerfier().then((hashedVerifier) => {
        localStorage.setItem(codeVerifierKey, hashedVerifier);
        api.login.getKeycloakAuthUrl({ RedirectUri: redirectUri, Locale: locale, Verifier: hashedVerifier }).then((result) => {
            if (!isEmpty(result.data)) {
                localStorage.setItem(redirectUrlKey, redirectUri);
                window.location.href = result.data;
            }
        });
    });
}

export function logoutKeycloak() {
    api.login.getKeycloakLogoutUrl({ RedirectUri: window.location.href.split('?')[0] + '/?error' }).then((result) => {
        window.location.href = result.data;
    });
}

function randomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export async function generateHashedVerfier() {
    const codeVerifier = randomString(32);
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    const base64Digest = base64encode(digest);
    // you can extract this replacing code to a function
    return base64Digest
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}
