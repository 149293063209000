import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { toast } from 'react-toastify';

import { fixConsole } from '../../common/utils/consoleFix';
import { notifyDefaultOptions } from '../../common/utils/notify';
import { is1to1, isGrantThornton, isSuf, isRiga, isMaxima } from '../../common/utils/whitelabelHelper';
import { NotificationTheme } from '../../components/Notifications/Notification';
import '../../i18n';

import Root, { RootProps } from './Root';

import InitHelpHero from 'helphero';

fixConsole();

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
global.cssVars = require('css-vars-ponyfill').default;

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
global.cssVars({
    watch: true,
    exclude: `link[ng-href*='css']`,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
global.CurrentVersion = process.env.REACT_APP_VERSION;
window.document.body.style.display = 'none';

if (isGrantThornton()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    import('./themes/grantthornton.scss').then(() => {
        window.document.body.style.display = '';
    });
} else if (is1to1()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    import('./themes/1to1.scss').then(() => {
        window.document.body.style.display = '';
    });
} else if (isSuf()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    import('./themes/suf.scss').then(() => {
        window.document.body.style.display = '';
    });
} else if (isRiga()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    import('./themes/riga.scss').then(() => {
        window.document.body.style.display = '';
    });
} else if (isMaxima()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    import('./themes/maxima.scss').then(() => {
        window.document.body.style.display = '';
    });
} else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    import('./themes/unifiedpost.scss').then(() => {
        window.document.body.style.display = '';
    });
}

const rootElement = document.getElementById('root');

// login page uses dark background
notifyDefaultOptions.theme = NotificationTheme.DARK;
/*
    In dev environment make App hot reloadable
 */
function initDev() {
    const AppContainer = require('react-hot-loader').AppContainer;
    const NextRoot: React.ComponentType<RootProps> = require('./Root').default;

    ReactDOM.render(
        <AppContainer warnings={false}>
            <NextRoot />
        </AppContainer>,
        rootElement,
    );
}

function init() {
    //Start Helphero on login page
    const helphero = InitHelpHero(process.env.REACT_APP_HELPHERO_KEY);
    helphero.anonymous();

    if (process.env.NODE_ENV === 'development') {
        initDev();

        if ((module as any).hot) {
            // on Root(and it's dependencies change) reload
            (module as any).hot.accept('./Root', () => {
                initDev();
            });
        }
    } else {
        ReactDOM.render(<Root />, rootElement);
    }
    toast.configure();
}

(function bootstrap() {
    init();
})();
