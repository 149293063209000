import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import errors from '../../../config/httpErrors';
import { BaseComponent } from '../../components/BaseComponent';
import { Button, ButtonSize, ButtonType } from '../../components/Buttons/Button';
import Icon, { ICONS } from '../../components/Icon/Icon';
import withSuspense from '../../common/hocs/withSuspense';

import './ErrorPage.scss';

export interface ErrorPageProps extends WithTranslation {}

type ErrorPageActionType = 'help' | 'dashboard';

const getErrorCodeFromUrl = (url: string) => {
    const splitUrl = url.match(/^.*\/(.*)\.\w+$/);
    if (splitUrl && splitUrl.length && errors.includes(splitUrl[1])) {
        return splitUrl[1];
    }
    return '404';
};

export class ErrorPage extends BaseComponent<ErrorPageProps> {
    handleClickButton = (actionType: ErrorPageActionType) => () => {
        const action: Record<ErrorPageActionType, VoidFunction> = {
            help: () => {
                window.location.href = 'https://fitekgroup.atlassian.net/wiki/spaces/FUG/overview';
            },
            dashboard: () => {
                window.location.href = '../../app/#/dashboard';
            },
        };
        action[actionType]?.();
    };

    render() {
        const { t } = this.props;
        const code = getErrorCodeFromUrl(window.location.href);

        return (
            <div className="error-container">
                <div className="error-container__content">
                    <span className="error-container__text--title error-container__text--medium">{t(`views.error.${code}.Description`)}</span>
                    <span className="error-container__text--title error-container__text--big">{code}</span>
                    <span className="error-container__text">{t(`views.error.Message`)}</span>

                    <div className="error-container__actions-wrapper">
                        <Button buttonSize={ButtonSize.SMALL} onClick={this.handleClickButton('dashboard')} buttonType={ButtonType.ACTION} className="outlined">
                            {t(`views.error.Action.Dashboard`)}
                        </Button>
                        <Button buttonSize={ButtonSize.SMALL} onClick={this.handleClickButton('help')} buttonType={ButtonType.PRIMARY} color="#fff" className="contained">
                            {t(`views.error.Action.Help`)}
                        </Button>
                    </div>
                </div>
                <Icon name={ICONS.EMPTY_DOCS} className="error-container__icon error-container__icon--right" />
            </div>
        );
    }
}

export default withSuspense(withTranslation()(ErrorPage));
