import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import { eventTrack } from '../../../common/analytics/gtm';
import { createDataId } from '../../../common/utils/dataId';

import { Button, ButtonType } from '../../../components/Buttons/Button';
import ContentBlockHeading from '../../../components/ContentBlock/ContentBlockHeading';
import { SelectOptionValue } from '../../../components/Select/SelectOption';
import { SUPPORTED_COUNTRIES } from '../loginHelper';

export interface CountryModalProps {
    children?: React.ReactNode;
    t: TFunction;
    setCountry: (countryCode: string) => void;
    selectedCountry: string;
    closeModal: () => void;
}

interface CountryModalValues {
    country: SelectOptionValue<string>;
}

const CountryModal: React.FunctionComponent<CountryModalProps> = (props: CountryModalProps) => {
    const { t } = props;
    const initialValues: CountryModalValues = {
        country: SUPPORTED_COUNTRIES.find((l) => l.value === props.selectedCountry),
    };
    const countriesList = useMemo(() => SUPPORTED_COUNTRIES.map((country) => ({ ...country, text: t(country.text) })).sort((a, b) => a.text.localeCompare(b.text)), []);
    return (
        <Formik initialValues={initialValues} onSubmit={() => {}} enableReinitialize={true} validateOnBlur={false} validateOnChange={false}>
            {(formik) => (
                <Form autoComplete="off">
                    <ContentBlockHeading dataId="CountryModalHeader">{t('views.login.region.modal')}</ContentBlockHeading>
                    <div className="login__supported-countries">
                        {countriesList.map((country) => (
                            <Button
                                isActive={country.value === formik.values.country.value}
                                className="login__supported-country"
                                dataId={createDataId('countryButton', country.value)}
                                buttonType={ButtonType.FLUID}
                                key={country.value}
                                onClick={() => {
                                    props.setCountry(country.value);
                                    eventTrack({
                                        event: 'change_country',
                                        label: country.value,
                                    });
                                    props.closeModal();
                                }}
                                icon={country.icon}
                                checkmark={country.value === formik.values.country.value}
                            >
                                {country.text}
                            </Button>
                        ))}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CountryModal;
