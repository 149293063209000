import { TFunction } from 'i18next';
import * as React from 'react';

export interface FooterProps {
    t: TFunction;
}

const year = new Date().getFullYear();

export const Footer: React.FunctionComponent<FooterProps> = ({ t }: FooterProps) => {
    return (
        <footer className="app__footer">
            <p className="app__copyright">{t('views.login.copyright', { year })}</p>
        </footer>
    );
};
